import React from 'react';
import NatureGuide from '../../../../components/guides/NatureComponent';
import Footer from '../../../../components/FooterComponent';

import { IsleOfSkye } from '../../../../guides/countries/scotland/isle-of-skye';

function IsleOfSkyePage(props) {
    return (
        <div>
            <NatureGuide nature={ IsleOfSkye } pathname={ props.location.pathname } urlBack="/travel-guides/europe/scotland" />
            <Footer />
        </div>
    );
}

export default IsleOfSkyePage;

import React from 'react';

export const IsleOfSkye = {
    "name": "Isle of Skye",
    "country": "Scotland",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50848046283_fa2d4048be_t.jpg",
    "image": "https://live.staticflickr.com/65535/50848046283_dc6d8b326c_o.jpg",
    "caption": "Iconic Scenery and Fantastic Landscapes",

    "brief": "Together with Edinburgh and Loch Ness, the Isle of Skye is one of Scotland's top three tourist destinations. However, crowds tend to be concentrated in Portree, Dunvegan and Trotternish - and it is almost always possible to find peace and tranquility in the furthest corners of the island. Come prepared for the weather changes: when it is good, it is very good, but it is not always so.",

    "description": <p>
            With fantastic landscapes, iconic scenery and unforgettable trails, you will have plenty of reasons to spend some of your
            days traveling to Scotland here. Although the island doesn't look very big on a map, there will be plenty of activities to
            enjoy. So, here we will present 3 different itineraries, depending on the number of days you will have at your disposal
            (our recommendation is a minimum of two days).
            <br/><br/>
            If you are short on time and you only have one day to visit the island, we recommend that you do the <b>Trotternish Loop</b>,
            starting with a quick visit to the <b>Fairy Pools</b>.
            With so much to see, we suggest that you start your day early (and of course ideally spend the night before in Portree).
            It’s usual to do the loop in counter-clockwise, but we recommend to travel <b>clockwise</b>, against the traffic, it will
            minimize crowds in some spots. The next stop would be the <b>Fairy Glen</b>, which will provide a short walk. Continuing the
            route to <b>Quiraing</b>, you will find here trails that can go up to 6km. The next location will be a quick stop, the <b>Kilt Rock
            and Mealt Falls</b>. Continuing the route we suggest that you visit a hidden gem of the island, but it is very worthwhile,
            the <b>Brother’s Point</b>. <b>The Old Man of Storr</b> is the next stop, and be prepared for a long way to get to the top. 45 minutes away
            it is the <b>Dunvegan Castle</b> (arrive before 5pm to entry). To finalize your day, assist to the sunset in <b>Neist Point</b>. Since you
            have a lot of activities for this day, we recommend that you grab a quick lunch to optimize time.
            <br/><br/>
            With two days on the island, it should be possible to dedicate more time to each activity. As such we suggest that you make the most of the
            first day by doing all the activities described above, with the exception of <b>Dunvegan Castle</b>, the <b>Fairy Pools</b> <b>Quiraing</b>.
            Save those two for the second day so you can see them calmly. However, do them on the afternoon, and start the day visiting the fantastic <b>Fairy Pools</b>.
            Then take the opportunity to pay a quick visit to the <b>Talisker Distillery</b>, and taste your good whiskey. Having lunch in the area then
            proceed to the Castle to continue your journey.
            <br/><br/>
            If you still have a third day to dedicate to the Island, we have two different options to follow. Or visit the <b>Bla Bheinn</b> if you love hiking,
            or get a boat trip from <b>Elgol</b> to <b>Loch Coruisk</b>.
            </p>,

    "nrDays": <p>
        To make the most out of everything the Isle of Skye has to offer we recommend at least <b>two full days</b> dedicated to this area.
        It will give you the opportunity to calmly visit all the main points of interest and discover a little more in depth the
        island following several trails.
    </p>,

    "whenVisit": <p>
        Like the rest of Scotland, during the summer this place tends to concentrate a large crowd of tourists.
        Although this area of Scotland is a little cooler, we recommend a visit in <b>May</b> and <b>early June</b>, or in <b>September</b>.
        Remember that even in these months, or even in the summer, rain is not unusual in the area, so come prepared for that.
    </p>,

    "getHere": <p>
        For a visit to the Isle of Skye you can always opt for a guided tour from the main cities in Scotland.
        However, that takes away your freedom and does not allow you to be amazed by the magnificent landscapes
        at your own pace.
        <br/><br/>
        As such, we hugely recommend you <b>rent a car</b> to visit this place. Although driving is on
        the opposite side to the “normal” side of the rest of the world, you will quickly get used to it and you
        will realize the enormous advantages of being able to go to all places on the island.
        We suggest <a href=" https://www.celticlegend.co.uk/car-rental-prices-scotland/" target="_blank" rel="noopener noreferrer">Celtic Legend</a>, we were very happy with their services.
        </p>,

    "activities": {
        "description": "The Isle of Skye is full of activities and places to visit. Here we will describe some of them, with tips for a fantastic experience.",
        "activities": [
            {
                "name": "Fairy Glen",
                "image": "https://live.staticflickr.com/65535/50848770136_4403da8c71_o.jpg",
                "description":
                    <div>
                       <p>
                       The mystical Fairy Glen is a must-see. We recommend an <b>early morning</b> visit to be able to observe the location
                       without a lot of tourists. This place is easy to hike and you will quickly reach the top. The arrangement of
                       rocks on the floor in this wonderful set of greens will raise your imagination and take you into a <b>fantasy world</b>.
                       </p>
                    </div>
            },
            {
                "name": "Quiraing",
                "image": "https://live.staticflickr.com/65535/50848046703_6d4bb67473_o.jpg",
                "description":
                    <div>
                        <p>
                            Quiraing is possible to observe from the car park. However, follow the hiking trail for at least 15
                            minutes to leave the crowds behind and to have the opportunity to observe only nature. If you feel
                            ready for the adventure, enjoy the complete circuit of approximately 7km.
                        </p>
                    </div>
            },
            {
                "name": "Kilt Rock and Mealt Falls",
                "image": "https://live.staticflickr.com/65535/50848770101_ec842192f6_o.jpg",
                "description":
                    <div>
                        <p>
                            This is probably the shortest stop of the day. However, it will offer an epic view of part of the
                            Isle of Skye coastline. From the viewpoint, looking north you will be able to see the <b>Mealt Falls
                            and Kilt Rock</b>. But don't forget to look to the south, as they will reveal the fantastic scenery of
                            <b>Brother’s Point</b>.
                        </p>
                    </div>
            },
            {
                "name": "Brother's Point",
                "image": "https://live.staticflickr.com/65535/50848770071_66eb0caff4_o.jpg",
                "description":
                    <div>
                        <p>
                        One of the hidden gem of this island. Also known as <b>Rubha Nam Brathairean</b>, this small trail will take you
                        to a small beach where you can watch the waves reaching a green meadow.
                        <br/><br/>
                        You will be amazed by the nature of
                        the area and you will also find some animals of the countryside close to the sea, something that is
                        not seen every day.
                        </p>
                    </div>
            },
            {
                "name": "Old Man Of Storr",
                "image": "https://live.staticflickr.com/65535/50848859052_ae536e71be_o.jpg",
                "description":
                <p>
                    Probably the <b>most known</b> place on the whole island. With a long walk to the top, you will realize that
                    all the effort is rewarded. With a magnificent view over lakes, mountains and the <b>Sound of Raasay</b>, a
                    kind of fjord. This is a fantastic setting where films like Prometheus and the Wickerman have been recorded.
                    A true icon of Skye, it can be seen for miles around.
                </p>
            },
            {
                "name": "Dunvegan Castle",
                "image": "https://live.staticflickr.com/65535/50848770026_ed01fe2de9_o.jpg",
                "description":
                    <div>
                        <p>
                        Known to be the ancestral home of the MacLeod clan chiefs, Dunvegan Castle is the oldest castle that
                        remains inhabited in Scotland. Originally built for the purpose of being a fortress, today it is possible
                        to visit an award-winning castle and a Highland property full of clan history, magic and legends, be
                        enchanted by the beauty of its gardens, or even take a boat trip to see the Loch Dunvegan seal colony.
                        </p>
                    </div>
            },
            {
                "name": "Neist Point",
                "image": "https://live.staticflickr.com/65535/50848046598_03b96ecc4a_o.jpg",
                "description":
                    <div>
                        <p>
                        The Neist point is approximately 30 minutes driving from Dunvegan. It can be difficult to park your
                        car during the most visited months, so always keep that time in mind. This is also the most popular
                        place to <b>watch the sunset</b>. You can walk to the lighthouse, which will take you between 1 and 2 hours
                        or just watch from the car.
                        </p>
                    </div>
            },
            {
                "name": "Fairy Pools",
                "image": "https://live.staticflickr.com/65535/50848046583_96e359b986_o.jpg",
                "description":
                    <div>
                        <p>
                        Fairy Pools are another very popular spot on the island. You will find large crowds walking
                        along the small waterfalls with the camera in hand to try to get the perfect picture of the water
                        with the mountains in the background. From Portree it is approximately 30 minutes to Fairy Pools,
                        so we recommend that you do it early in the morning to avoid the crowds.
                        <br/><br/>
                        On your way there you will pass by the <b>Sligachan Old Bridge</b>, a famous spot for photographies on the island.
                        </p>
                    </div>
            },
            {
                "name": "Bla Bheinn",
                "image": "https://live.staticflickr.com/65535/50848769926_bdde5b95e3_o.jpg",
                "description":
                    <div>
                        <p>
                        For true hiking adventures. With a good sunny day, this is a perfect place for a long walk.
                        A fantastic trail that offers 360º views over the <b>Cuillins</b>.
                        <br/><br/>
                        The trail starts next to the Loch Slapin car park. The first portion of the trail has a slight
                        climb through the forest. Along the route, you will have the River <b>Alt na Dunaiche</b> on your left,
                        where you will see a wonderful waterfall in the background. To cross the river you will have to
                        take advantage of one of the small stone bridges along the way. On rainy days it can be tricky to
                        do so. The complete route has approximately 7km and a climb of 1000m.
                        </p>
                    </div>
            },
            {
                "name": "Loch Coruisk",
                "image": "https://live.staticflickr.com/65535/50848769891_f5e3438c6a_o.jpg",
                "description":
                    <div>
                        <p>
                        This is a trip that will take up almost the entire day. Departing from <b>Elgol</b>, a boat will take you to <b>Loch Coruisk</b>.
                        You will have the opportunity to observe orcas, dolphins and puffins. Once you arrive at your destination you will
                        be able to admire the fantastic landscapes of the lake, spotting seals on the shore.
                        </p>
                    </div>
            },
        ]
    },

    "food": {
        "description": "Most restaurants in the Isle Of Skye are located in Portree, and offers a few interesting stops during your adventures to have something to eat or drink.",

        "restaurants": [
            {
                "name": "The Red Brick Cafe",
                "description": "Quite famous for providing good breakfasts, this spacious place is an option if you stay on your route. The staff is nice.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50848769856_7ac7c1239f_o.jpg"
            },
            {
                "name": "Pizza in the Skye",
                "description": "Fantastic pizza in a take away service in a small house. Fantastic and cheap service.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50848046433_954e64ce88_o.jpg"
            },
            {
                "name": "Claymore Restaurant",
                "description": "The food is very well prepared and tasty, the atmosphere is calm and pleasant.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50848046423_3ace8a29cd_o.jpg"
            },
            {
                "name": "Red Roof",
                "description": "Although it is not your typical coffee shop, everything else is special. The food tastes great, ethically sourced and well presented. The service is really good.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50848858872_6f5bf023d2_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "For the visit to Isle of Skye we recommend that you book your stay in Portree. It is the largest city on the island and has good access to the various points of interest.",

        "accomodations": [
            {
                "name": "Portree Independent Hostel",
                "image": "https://live.staticflickr.com/65535/50848769761_eb69bd5d01_o.jpg",
                "description": "Not very luxurious, but with a relative low price for the country, Portree Independent Hostel is located 28 km from the Talisker Distillery. Facilities include a shared kitchen, a shared lounge and free WiFi in all areas.",
                "link": ""
            },
            {
                "name": "Portree Youth Hostel",
                "image": "https://live.staticflickr.com/65535/50848858847_8b77f4c8e9_o.jpg",
                "description": "This hostel has a shared dining area and a common lounge. All rooms are heated and very comfortable. Some have a private bathroom with a bath or shower and others have a shared bathroom.",
                "link": ""
            },
            {
                "name": "Cuillin Hills Hotel",
                "image": "https://live.staticflickr.com/65535/50848769726_ea43cebf99_o.jpg",
                "description": "This luxurious and very expensive hotel has spectacular views over Portree Bay and the Cuillin Mountain Range. It features an award-winning restaurant and serves a variety of whiskeys.",
                "link": ""
            },
            {
                "name": "Raasay House Hotel",
                "image": "https://live.staticflickr.com/65535/50848858777_11cf711ef3_o.jpg",
                "description": "Although not exactly located in the Isle of Skye, but instead on the close by Isle of Raasay, this is a magnificent award-winning hotel at an affordable price. It is necessary to use the ferry to reach the island, but once in it, you can take a walk around the area and enjoy nature.",
                "link": ""
            }
        ]
    }
}
